import {
  $getSelection,
  $setSelection,
  $isRangeSelection,
  $isElementNode,
  RangeSelection,
  LexicalNode,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
  COMMAND_PRIORITY_LOW,
  $createRangeSelection,
  $getRoot,
  ElementNode,
  $isTextNode,
  TextNode,
  RootNode,
} from "lexical";
import {FormEventHandler, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {mergeRegister} from "@lexical/utils";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {
  SimplePortal,
  css,
  Row,
  DSIconButton,
  DSIconBold,
  DSIconCheck,
  DSIconCode,
  DSIconItalic,
  DSIconLink,
  DSIconStrikeThrough,
  DSInput,
} from "@cdx/ds";
import {findScrollableParents} from "@cdx/ds/hooks/position-hooks";

import {
  MdOp,
  SelectionProps,
  addMarkdownFormat,
  mdAstSelectionProps,
  removeMarkdownFormat,
  textToMarkdownAST,
} from "./markdown-helpers/markdown-helpers";
import {getZIndex} from "@cdx/ds/utils/dom-utils";
import {TooltipForChild, useEsc} from "@cdx/common";
import {
  SelectionInfo,
  getSelectionInfo,
} from "@cdx/ds/components/DSTextEditor/CdxSmartPlainTextCompletionPlugin/smartIndentHelpers";

const LinkEdit = (props: {
  selectionProps: SelectionProps;
  onAddLink: (url: string) => void;
  onClose: () => void;
}) => {
  const {onClose, selectionProps, onAddLink} = props;
  const [val, setVal] = useState(selectionProps.link ? selectionProps.link.url : "");
  const nodeRef = useRef<HTMLInputElement>(null);
  useLayoutEffect(() => {
    nodeRef.current!.focus();
  }, []);
  useEsc(onClose);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onAddLink(val);
  };

  return (
    <form onSubmit={handleSubmit} className={css({sp: "4px", align: "center", display: "flex"})}>
      <DSInput
        placeholder="https://example.com"
        value={val}
        onChange={setVal}
        ref={nodeRef}
        size="sm"
      />
      <DSIconButton variant="tertiary" size="sm" icon={<DSIconCheck />} type="submit" />
      <TooltipForChild tooltip="Cancel">
        <DSIconButton variant="tertiary" size="sm" onClick={onClose} icon={<DSIconLink />} active />
      </TooltipForChild>
    </form>
  );
};

const TOOLBAR_HEIGHT = 32 + 4;

const inlineFormats = [
  {
    formatType: "strong",
    tooltip: "Format text as bold",
    content: <DSIconBold />,
    prop: "isBold",
    toAdd: "**",
  },
  {
    formatType: "emphasis",
    tooltip: "Format text as italics",
    content: <DSIconItalic />,
    prop: "isItalic",
    toAdd: "*",
  },
  {
    formatType: "delete",
    tooltip: "Format text with a strikethrough",
    content: <DSIconStrikeThrough />,
    prop: "isStrikeThrough",
    toAdd: "~~",
  },
  {
    formatType: "inlineCode",
    tooltip: "Format text as code",
    content: <DSIconCode />,
    prop: "isCode",
    toAdd: "`",
  },
] as const;

const getDOMRangeRect = (nativeSelection: Selection, rootElement: HTMLElement): DOMRect => {
  const domRange = nativeSelection.getRangeAt(0);

  let rect;

  if (nativeSelection.anchorNode === rootElement) {
    let inner = rootElement;
    while (inner.firstElementChild != null) {
      inner = inner.firstElementChild as HTMLElement;
    }
    rect = inner.getBoundingClientRect();
  } else {
    rect = domRange.getBoundingClientRect();
  }

  return rect;
};

const $getLeavesStartingFrom = (n: RootNode, startBlock: LexicalNode | null) => {
  const leaves: LeafInfo[] = [];
  let seen = startBlock === null;
  for (const child of n.getChildren()) {
    if (!seen && child === startBlock) seen = true;
    if (seen) $getLeaves(child, leaves);
  }
  return leaves;
};

type LeafInfo = {node: LexicalNode; offset: number; length: number; shift: number};
const $getLeaves = (n: LexicalNode | ElementNode, leaves: LeafInfo[] = []): LeafInfo[] => {
  if ($isElementNode(n)) {
    for (const child of n.getChildren()) $getLeaves(child, leaves);
  } else {
    const prevLeaf = leaves[leaves.length - 1];
    const offset = prevLeaf ? prevLeaf.offset + prevLeaf.length : 0;
    leaves.push({offset, length: n.getTextContentSize(), shift: 0, node: n});
  }
  return leaves;
};

const $addTextAt = (sel: RangeSelection, node: TextNode, pos: number, text: string) => {
  sel.setTextNodeRange(node, pos, node, pos);
  sel.insertText(text);
};

const applyMdOps = (
  editor: LexicalEditor,
  ops: MdOp[],
  selInfo: SelectionInfo,
  startStr: string,
  endStr: string = startStr
) => {
  if (!ops.length) return;
  editor.update(() => {
    const off = -selInfo.lineOffsetWithinBlock;
    const leaves = $getLeavesStartingFrom($getRoot(), selInfo.startBlock);
    const sel = $createRangeSelection();
    let prevDelOp: null | {endPos: number; leaf: LeafInfo} = null;
    for (const op of ops) {
      for (const leaf of leaves) {
        const {node, offset, length} = leaf;
        // todo: speed up via binary search!
        if (!$isTextNode(node)) continue;
        const relPos = offset + off;
        switch (op.type) {
          case "add": {
            const {start, end} = op;
            if (relPos <= start && relPos + length > start) {
              $addTextAt(sel, node, start - relPos + leaf.shift, startStr);
              leaf.shift += startStr.length;
            }
            if (relPos < end && relPos + length >= end) {
              $addTextAt(sel, node, end - relPos + leaf.shift, endStr);
              leaf.shift += endStr.length;
              sel.setTextNodeRange(
                node,
                start - relPos + startStr.length,
                node,
                end - relPos + startStr.length
              );
            }
            break;
          }
          case "del": {
            const {start, len} = op;
            // check if leaf is relevant
            if (relPos <= start && relPos + length > start) {
              const pos = start - relPos + leaf.shift;
              sel.setTextNodeRange(node, pos, node, pos + len);
              sel.removeText();
              if (prevDelOp && prevDelOp.leaf === leaf) {
                sel.setTextNodeRange(node, prevDelOp.endPos, node, pos);
                prevDelOp = null;
              } else {
                sel.setTextNodeRange(node, pos, node, pos);
                prevDelOp = {endPos: pos, leaf};
              }
              leaf.shift += -len;
            }
            break;
          }
          case "replace": {
            const {newStr, oldLen, start} = op;
            if (relPos <= start && relPos + length > start) {
              const pos = start - relPos + leaf.shift;
              sel.setTextNodeRange(node, pos, node, pos + oldLen);
              sel.insertText(newStr);
              sel.setTextNodeRange(node, pos, node, pos);
              leaf.shift += oldLen - newStr.length;
            }
          }
        }
      }
    }
    $setSelection(sel);
  });
};
type FloatingToolbarProps = {
  showingLinkEdit: boolean;
  showLinkEdit: () => void;
  cancelLinkEdit: () => void;
  closeLinkEdit: () => void;
  selectionInfo: SelectionInfo;
  editor: LexicalEditor;
};

const FloatingToolbar = (props: FloatingToolbarProps) => {
  const {editor, selectionInfo, showingLinkEdit, showLinkEdit, cancelLinkEdit, closeLinkEdit} =
    props;
  const propsRef = useRef(props);
  useEffect(() => {
    propsRef.current = props;
  });
  const [zIndex] = useState(() => (getZIndex(editor.getRootElement()!) || 0) + 3);
  const nodeRef = useRef<HTMLElement>(null);

  const mdAst = useMemo(() => textToMarkdownAST(selectionInfo.text), [selectionInfo.text]);

  const selectionProps = mdAstSelectionProps(mdAst, {
    start: selectionInfo.startOffset,
    end: selectionInfo.endOffset,
  });

  useEffect(() => {
    const rootElement = editor.getRootElement();
    if (!rootElement) return;
    const updateTextFormatFloatingToolbar = () => {
      const selection = $getSelection();
      const nativeSelection = window.getSelection();

      if (
        selection !== null &&
        nativeSelection !== null &&
        !nativeSelection.isCollapsed &&
        rootElement.contains(nativeSelection.anchorNode) &&
        nodeRef.current !== null
      ) {
        const {top, left, bottom, right} = getDOMRangeRect(nativeSelection, rootElement);
        if (top === bottom && left === right) return;
        const displayTop = Math.max(top, TOOLBAR_HEIGHT + 5) + window.pageYOffset - TOOLBAR_HEIGHT;
        nodeRef.current.style.top = `${displayTop}px`;
        nodeRef.current.style.left = `${left + window.pageXOffset}px`;
      }
    };
    const update = () => {
      editor.getEditorState().read(() => {
        updateTextFormatFloatingToolbar();
      });
    };
    update();
    window.addEventListener("resize", update);
    const parents = findScrollableParents(rootElement);
    parents.forEach((parent) => parent.addEventListener("scroll", update));
    const unsubs = mergeRegister(
      editor.registerUpdateListener(({editorState}) => {
        editorState.read(() => {
          updateTextFormatFloatingToolbar();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateTextFormatFloatingToolbar();
          if (propsRef.current.showingLinkEdit) {
            propsRef.current.cancelLinkEdit();
          }
          return false;
        },
        COMMAND_PRIORITY_LOW
      )
    );
    return () => {
      window.removeEventListener("resize", update);
      parents.forEach((parent) => parent.removeEventListener("scroll", update));
      unsubs();
    };
  }, [editor]);

  const handleLinkClick = () => {
    if (selectionProps.link) {
      const ops = removeMarkdownFormat(
        mdAst,
        {
          start: selectionInfo.startOffset,
          end: selectionInfo.endOffset,
        },
        "link"
      );
      applyMdOps(editor, ops, selectionInfo, "none");
      closeLinkEdit();
    } else {
      showLinkEdit();
    }
  };

  return (
    <Row
      sp="4px"
      pa="4px"
      rounded={4}
      absolute
      bg="foreground"
      elevation={200}
      ref={nodeRef}
      style={{zIndex}}
    >
      {showingLinkEdit ? (
        <LinkEdit
          onAddLink={(url) => {
            const ops = addMarkdownFormat(mdAst, {
              start: selectionInfo.startOffset,
              end: selectionInfo.endOffset,
            });
            applyMdOps(editor, ops, selectionInfo, "[", `](${url})`);
            closeLinkEdit();
          }}
          selectionProps={selectionProps}
          onClose={cancelLinkEdit}
        />
      ) : (
        <>
          {inlineFormats.map(({content, formatType, tooltip, prop, toAdd}) => (
            <TooltipForChild tooltip={tooltip} key={formatType}>
              <DSIconButton
                onClick={() => {
                  const pos = {start: selectionInfo.startOffset, end: selectionInfo.endOffset};
                  const ops = selectionProps[prop]
                    ? removeMarkdownFormat(mdAst, pos, formatType)
                    : addMarkdownFormat(mdAst, pos);
                  applyMdOps(editor, ops, selectionInfo, toAdd);
                }}
                active={selectionProps[prop]}
                variant="tertiary"
                size="sm"
                icon={content}
              />
            </TooltipForChild>
          ))}
          <TooltipForChild tooltip={selectionProps.link ? "Remove link" : "Add link"}>
            <DSIconButton
              onClick={handleLinkClick}
              aria-label="Insert Link"
              variant="tertiary"
              size="sm"
              active={Boolean(selectionProps.link)}
              icon={<DSIconLink />}
            />
          </TooltipForChild>
        </>
      )}
    </Row>
  );
};

export const CdxFloatingPlainTextFormatterPlugin = () => {
  const [editor] = useLexicalComposerContext();
  const [selectedTextInfo, setSelectedTextInfo] = useState<null | SelectionInfo>(null);
  const [showingLinkEdit, setShowingLinkEdit] = useState(false);
  const refs = useRef({showingLinkEdit, cachedSelection: null as null | RangeSelection});
  useLayoutEffect(() => {
    refs.current.showingLinkEdit = showingLinkEdit;
  });

  useEsc(() => {
    if (!selectedTextInfo) return false;
    setSelectedTextInfo(null);
  });

  const handleShowLinkEdit = () => {
    setShowingLinkEdit(true);
    editor.getEditorState().read(() => {
      refs.current.cachedSelection = ($getSelection() as RangeSelection).clone();
    });
  };
  const handleCloseLinkEdit = () => {
    setShowingLinkEdit(false);
  };
  const handleCancelLinkEdit = () => {
    setShowingLinkEdit(false);
    const sel = refs.current.cachedSelection;
    if (sel) {
      editor.update(() => {
        // TODO, update cache if selection was changed by editing
        $setSelection(sel.clone());
      });
    }
    refs.current.cachedSelection = null;
  };

  useLayoutEffect(() => {
    const updatePopup = () => {
      if (refs.current.showingLinkEdit) return;
      editor.getEditorState().read(() => {
        if (editor.isComposing()) {
          return;
        }
        const selection = $getSelection();
        const nativeSelection = window.getSelection();
        const rootElement = editor.getRootElement();

        if (
          nativeSelection !== null &&
          (!$isRangeSelection(selection) ||
            rootElement === null ||
            !rootElement.contains(nativeSelection.anchorNode))
        ) {
          setSelectedTextInfo(null);
          return;
        }

        if (!$isRangeSelection(selection) || selection.isCollapsed()) {
          setSelectedTextInfo(null);
          return;
        }
        setSelectedTextInfo(getSelectionInfo(selection));
      });
    };

    document.addEventListener("selectionchange", updatePopup);
    const unsubs = mergeRegister(
      editor.registerUpdateListener(() => {
        updatePopup();
      }),
      editor.registerRootListener(() => {
        if (editor.getRootElement() === null) {
          setSelectedTextInfo(null);
        }
      })
    );
    return () => {
      unsubs();
      document.removeEventListener("selectionchange", updatePopup);
    };
  }, [editor]);
  if (!selectedTextInfo) return null;
  return (
    <SimplePortal>
      <FloatingToolbar
        editor={editor}
        selectionInfo={selectedTextInfo}
        showingLinkEdit={showingLinkEdit}
        showLinkEdit={handleShowLinkEdit}
        cancelLinkEdit={handleCancelLinkEdit}
        closeLinkEdit={handleCloseLinkEdit}
      />
    </SimplePortal>
  );
};
