import {
  $getSelection,
  $createTextNode,
  $isRangeSelection,
  TextNode,
  KEY_ARROW_DOWN_COMMAND,
  KEY_ARROW_UP_COMMAND,
  KEY_TAB_COMMAND,
  KEY_ENTER_COMMAND,
  COMMAND_PRIORITY_NORMAL,
  LexicalEditor,
  LexicalNode,
  RangeSelection,
  KEY_ESCAPE_COMMAND,
} from "lexical";
import {MutableRefObject, ReactNode, forwardRef, useEffect, useMemo, useRef, useState} from "react";
import {mergeRegister} from "@lexical/utils";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {Box, Col, css, Row, DSSpinner, SimplePortal} from "@cdx/ds";
import {makeScrollable} from "@cdx/ds/utils/makeScrollable";
import {useDSDimensions} from "@cdx/ds/hooks/position-hooks";
import {getZIndex} from "@cdx/ds/utils/dom-utils";
import {iconStyles} from "@cdx/ds/components/DSIcon/DSIcon.css";

export type ResultProps<T> = {
  data: T[];
  editor: LexicalEditor;
  onClose: () => void;
  onSelect: (val: T) => void;
  optRef: MutableRefObject<Args<T>>;
  groupInfo?: GroupsInfo<T>;
};

const OptEntry = ({isSelected, onClick, children}: any) => {
  const nodeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isSelected) {
      nodeRef.current?.scrollIntoView(false);
    }
  }, [isSelected]);

  return (
    <div
      data-cdx-clickable
      onClick={onClick}
      ref={nodeRef}
      className={css({
        ...(isSelected && {bg: "foreground", colorTheme: "active50", rounded: 4}),
        px: "24px",
        py: "8px",
        cursor: "pointer",
        useHoverBg: "true",
        textType: "prose16",
      })}
    >
      {children}
    </div>
  );
};

type GroupContentProps<T> = {
  data: T[];
  optRef: MutableRefObject<Args<T>>;
  selIdx: number;
  refs: MutableRefObject<ResultProps<T>>;
  offset?: number;
};
const GroupContent = forwardRef<HTMLElement, GroupContentProps<any>>((props, ref) => {
  const {data, optRef, selIdx, refs, offset = 0} = props;
  return (
    <Col ref={ref}>
      {data.map((el, idx) => (
        <OptEntry
          key={optRef.current.optionToKey(el)}
          isSelected={idx + offset === selIdx}
          onClick={() => refs.current.onSelect(el)}
        >
          {optRef.current.renderOption(el)}
        </OptEntry>
      ))}
    </Col>
  );
});

const ShowResults = forwardRef<HTMLElement, ResultProps<any>>((props, ref) => {
  const {data, editor, optRef, groupInfo} = props;
  const [selIdx, setSelIdx] = useState(0);

  const groups = useMemo(() => {
    if (!groupInfo) return null;
    const byGroup: {[key: string]: {info: Group; opts: unknown[]; key: string}} = {};
    data.forEach((opt) => {
      const key = groupInfo.getKey(opt);
      const exist = byGroup[key];
      if (exist) {
        exist.opts.push(opt);
      } else {
        byGroup[key] = {
          key,
          info: groupInfo.getGroup(key),
          opts: [opt],
        };
      }
    });
    const values = Object.values(byGroup);
    values.sort((a, b) => a.info.prio - b.info.prio);
    return values;
  }, [data, groupInfo]);

  const refs = useRef({...props, groups, selIdx});
  useEffect(() => {
    refs.current = {...props, groups, selIdx};
  });

  useEffect(() => {
    const select = (event: KeyboardEvent) => {
      if (!refs.current.data?.length) return false;
      const getOpt = () => {
        if (!refs.current.groups) return refs.current.data[refs.current.selIdx];
        let i = 0;
        for (const {opts} of refs.current.groups) {
          for (const o of opts) {
            if (i === refs.current.selIdx) return o;
            i += 1;
          }
        }
        return null;
      };
      const opt = getOpt();
      if (!opt) return false;

      event.preventDefault();
      event.stopImmediatePropagation();
      refs.current.onSelect(opt);
      return true;
    };
    return mergeRegister(
      editor.registerCommand<KeyboardEvent>(
        KEY_ARROW_DOWN_COMMAND,
        (event) => {
          if (!refs.current.data?.length) return false;
          const dataLen = refs.current.data.length;
          setSelIdx((prev) => Math.min(prev + 1, dataLen - 1));
          event.preventDefault();
          event.stopImmediatePropagation();
          return true;
        },
        COMMAND_PRIORITY_NORMAL
      ),
      editor.registerCommand<KeyboardEvent>(
        KEY_ARROW_UP_COMMAND,
        (event) => {
          if (!refs.current.data?.length) return false;
          setSelIdx((prev) => Math.max(prev - 1, 0));
          event.preventDefault();
          event.stopImmediatePropagation();
          return true;
        },
        COMMAND_PRIORITY_NORMAL
      ),
      editor.registerCommand<KeyboardEvent>(KEY_TAB_COMMAND, select, COMMAND_PRIORITY_NORMAL),
      editor.registerCommand<KeyboardEvent>(KEY_ENTER_COMMAND, select, COMMAND_PRIORITY_NORMAL)
    );
  }, [editor, setSelIdx]);
  let groupOffset = 0;

  return groups ? (
    <Col divideX ref={ref}>
      {groups.map(({info, opts, key}) => (
        <Col key={key} py="8px">
          <Row
            px="16px"
            py="8px"
            color="secondary"
            sp="8px"
            className={iconStyles.sizes[20]}
            align="center"
          >
            {info.icon}
            <Box as="h3" bold size={11} textTransform="uppercase">
              {info.label}
            </Box>
          </Row>
          <GroupContent
            data={opts}
            refs={refs}
            optRef={optRef}
            selIdx={selIdx}
            offset={(groupOffset += opts.length) - opts.length}
          />
        </Col>
      ))}
    </Col>
  ) : (
    <GroupContent data={data} refs={refs} optRef={optRef} selIdx={selIdx} ref={ref} />
  );
});

const getRange = (leadOffset: number): Range | null => {
  const domSelection = window.getSelection();
  if (domSelection === null || !domSelection.isCollapsed) {
    return null;
  }
  const anchorNode = domSelection.anchorNode;
  const startOffset = leadOffset;
  const endOffset = domSelection.anchorOffset;

  if (anchorNode == null || endOffset == null) {
    return null;
  }
  const range = document.createRange();

  try {
    range.setStart(anchorNode, startOffset);
    range.setEnd(anchorNode, endOffset);
  } catch (error) {
    return null;
  }

  return range;
};

const $getTextLeftOfCursor = () => {
  const selection = $getSelection();
  if (!selection || !$isRangeSelection(selection)) return null;
  if (!selection.isCollapsed()) return null;
  const anchor = selection.anchor;
  if (anchor.type !== "text") {
    return null;
  }
  const anchorNode = anchor.getNode();
  if (!anchorNode.isSimpleText()) {
    return null;
  }
  const anchorOffset = anchor.offset;
  return {
    anchorNode,
    anchorOffset,
    cursorText: anchorNode.getTextContent().slice(0, anchorOffset),
  };
};

const $getCurrWordRect = (offset: number) => {
  const range = getRange(offset);
  if (range) return range.getBoundingClientRect();
  return null;
};

const getQueryManager = <T extends any>(debounceMs: number) => {
  const cache = new Map<
    string,
    {type: "pending"; payload: Promise<T[]>} | {type: "loaded"; payload: T[]}
  >();
  let currWaiting: {val: string; timeoutId: ReturnType<typeof setTimeout>} | null = null;
  return {
    get: (val: string, fn: Args<T>["getOptions"], onResults: (data: T[]) => void) => {
      if (currWaiting) {
        if (currWaiting.val === val) return;
        clearTimeout(currWaiting.timeoutId);
        currWaiting = null;
      }
      const cacheVal = cache.get(val);
      if (cacheVal) {
        if (cacheVal.type === "pending") {
          cacheVal.payload.then(onResults);
        } else {
          onResults(cacheVal.payload);
        }
      } else {
        currWaiting = {
          val,
          timeoutId: setTimeout(() => {
            currWaiting = null;
            const cacheEntry = {
              type: "pending" as const,
              payload: fn(val),
            };
            cache.set(val, cacheEntry);
            cacheEntry.payload.then((res) => {
              cache.set(val, {type: "loaded", payload: res});
              onResults(res);
            });
          }, debounceMs),
        };
      }
    },
  };
};

type Resolution<T> = {
  rect: {left: number; top: number; bottom: number; right: number};
  anchorNode: TextNode;
  anchorOffset: number;
  matchStart: number;
  result: {state: "loading"} | {state: "loaded"; data: T[]};
  prefix?: string;
};

export type TypeAheadResolution<T> = Resolution<T>;

type TypeAheadTransformer<T> = (
  option: T,
  selection: RangeSelection,
  resolution: Resolution<T>
) => void;

type SelectionHandler<T> = (option: T, editor: LexicalEditor, resolution: Resolution<T>) => void;

export const createNodeHandler = <T extends any>(toNodeFn: (option: T) => LexicalNode) =>
  textUpdateSelectionHandler((option: T, selection: RangeSelection) => {
    selection.insertNodes([toNodeFn(option), $createTextNode(" ")]);
  });

export const textUpdateSelectionHandler = <T extends any>(
  transformer: TypeAheadTransformer<T>
): SelectionHandler<T> => {
  return (option, editor, resolution) => {
    const {anchorNode, anchorOffset, matchStart} = resolution;
    editor.update(() => {
      const selection = $getSelection();
      if (!$isRangeSelection(selection)) return;
      let newNode;
      if (matchStart === 0) {
        [newNode] = anchorNode.splitText(anchorOffset);
      } else {
        [, newNode] = anchorNode.splitText(matchStart, anchorOffset);
      }
      if (newNode) newNode.remove();
      transformer(option, selection, resolution);
    });
  };
};

type TriggerMatch = {offset: number; match: string; prefix?: string};

type ResultComp<T extends any> = (props: ResultProps<T>) => JSX.Element;

type Group = {
  label: string;
  prio: number;
  icon?: ReactNode;
};

export type CdxTypeaheadGroupInfo = Group;
type GroupsInfo<T> = {
  getGroup: (key: string) => Group;
  getKey: (option: T) => string;
};

type Args<T> = {
  triggerFn: (text: string) => TriggerMatch | null;
  getOptions: (match: string) => Promise<T[]>;
  handleOptionSelect: SelectionHandler<T>;
  renderOption: (option: T) => ReactNode;
  optionToKey: (option: T) => string;
  debounceMs?: number;
  resultComp?: ResultComp<T>;
  groupInfo?: GroupsInfo<T>;
};

const useWindowSize = () => {
  const [dims, setDims] = useState({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  });
  useEffect(() => {
    const onUpdate = () =>
      setDims({
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      });
    window.addEventListener("resize", onUpdate);
    return () => window.removeEventListener("resize", onUpdate);
  }, []);
  return dims;
};

const Overlay = <T extends any>(props: {
  resolution: Resolution<T>;
  editor: LexicalEditor;
  onClose: () => void;
  optRef: MutableRefObject<Args<T>>;
}) => {
  const {resolution, editor, onClose, optRef} = props;
  const [zIndex] = useState(() => (getZIndex(editor.getRootElement()!) || 0) + 3);
  const {height: winHeight} = useWindowSize();
  const [contentRef, dims] = useDSDimensions();
  const {resultComp: Comp = ShowResults, groupInfo} = optRef.current;
  const handleSelect = (arg: T) => {
    if (!resolution) return;
    optRef.current.handleOptionSelect(arg, editor, resolution);
    onClose();
  };

  const propsRef = useRef(props);
  propsRef.current = props;

  const getStyle = () => {
    const maxHeight = 410;
    const compHeight = dims ? Math.min(maxHeight, dims.height) : 60;
    const spaceToBottom =
      winHeight - (resolution.rect.top + Math.min(winHeight * 0.33, compHeight));
    if (spaceToBottom < 10) {
      // display on top
      const bottom = winHeight - resolution.rect.top;
      return {
        bottom,
        left: resolution.rect.left,
        zIndex,
        maxHeight: Math.min(maxHeight, winHeight - bottom - 10),
      };
    } else {
      // display below
      const top = resolution.rect.bottom;
      return {
        top,
        left: resolution.rect.left,
        zIndex,
        maxHeight: Math.min(maxHeight, winHeight - top - 10),
      };
    }
  };

  useEffect(() => {
    return editor.registerCommand<KeyboardEvent>(
      KEY_ESCAPE_COMMAND,
      (e) => {
        e.stopPropagation();
        propsRef.current.onClose();
        return true;
      },
      COMMAND_PRIORITY_NORMAL
    );
  }, [editor]);

  return (
    <SimplePortal>
      <Box
        absolute
        style={getStyle()}
        bg="foreground"
        elevation={200}
        rounded={4}
        className={makeScrollable()}
      >
        {resolution.result.state === "loading" ? (
          <Col pa="16px" align="center">
            <DSSpinner size={24} />
          </Col>
        ) : (
          <Comp
            data={resolution.result.data}
            groupInfo={groupInfo}
            editor={editor}
            onClose={onClose}
            onSelect={handleSelect}
            optRef={optRef}
            ref={contentRef}
          />
        )}
      </Box>
    </SimplePortal>
  );
};

const CdxTypeaheadPlugin = <T extends any>(opts: Args<T>) => {
  const [queryManager] = useState(() => getQueryManager<T>(opts.debounceMs ?? 200));
  const optRef = useRef(opts);
  const [resolution, setResolution] = useState<Resolution<T> | null>(null);
  useEffect(() => {
    optRef.current = opts;
  });
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    return editor.registerUpdateListener(() => {
      editor.getEditorState().read(() => {
        const res = $getTextLeftOfCursor();
        if (res) {
          const m = optRef.current.triggerFn(res.cursorText);
          if (m) {
            const rect = $getCurrWordRect(m.offset);
            if (rect) {
              setResolution({
                rect,
                result: {state: "loading"},
                anchorNode: res.anchorNode,
                anchorOffset: res.anchorOffset,
                matchStart: m.offset,
                prefix: m.prefix,
              });
              queryManager.get(
                m.match,
                (arg) => optRef.current.getOptions(arg),
                (data) =>
                  setResolution((prev) => {
                    if (!prev) return prev;
                    return {...prev, result: {state: "loaded", data}};
                  })
              );
              return;
            }
          }
        }
        setResolution(null);
      });
    });
  }, [editor, queryManager]);
  if (!resolution) return null;
  return (
    <Overlay
      editor={editor}
      onClose={() => setResolution(null)}
      resolution={resolution}
      optRef={optRef}
    />
  );
};

export default CdxTypeaheadPlugin;
