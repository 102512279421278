import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  LexicalNode,
  NodeKey,
  Spread,
  $applyNodeReplacement,
  TextNode,
  $getSelection,
  $isRangeSelection,
  $getEditor,
  ElementFormatType,
  $splitNode,
  $isLineBreakNode,
  CLICK_COMMAND,
} from "lexical";
import {useEffect} from "react";
import useLexicalDecoratorNode from "@cdx/ds/components/DSTextEditor/useLexicalDecoratorNode";
import {
  DecoratorBlockNode,
  SerializedDecoratorBlockNode,
} from "@lexical/react/LexicalDecoratorBlockNode";
import {css} from "@cdx/ds";
import {RawMarkdownImg} from "../../Markdown/MarkdownMedia";
import {getVideoMimeTypeFromUrl} from "../../../lib/file-utils";

export interface ImagePayload {
  altText: string;
  height?: number;
  key?: NodeKey;
  format?: ElementFormatType;
  src: string;
  width?: number;
}

function convertImageElement(domNode: Node): null | DOMConversionOutput {
  if (domNode instanceof HTMLImageElement) {
    const {alt: altText, src, width, height} = domNode;
    const node = $createImageNode({altText, height, src, width});
    return {node};
  }
  return null;
}

export type SerializedImageNode = Spread<
  {
    altText: string;
    height?: number;
    src: string;
    width?: number;
  },
  SerializedDecoratorBlockNode
>;

export class ImageNode extends DecoratorBlockNode {
  __src: string;
  __alt: string;
  __width: "inherit" | number;
  __height: "inherit" | number;

  static getType(): string {
    return "cdxImage";
  }

  static clone(node: ImageNode): ImageNode {
    return new ImageNode(
      node.__src,
      node.__alt,
      node.__width,
      node.__height,
      node.__format,
      node.__key
    );
  }

  static importJSON(serializedNode: SerializedImageNode): ImageNode {
    const {altText, height, width, src} = serializedNode;
    const node = $createImageNode({
      altText,
      height,
      src,
      width,
    });
    return node;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("img");
    element.setAttribute("src", this.__src);
    element.setAttribute("alt", this.__alt);
    element.setAttribute("width", this.__width.toString());
    element.setAttribute("height", this.__height.toString());
    return {element};
  }

  static importDOM(): DOMConversionMap | null {
    return {
      img: (node: Node) => ({
        conversion: convertImageElement,
        priority: 0,
      }),
    };
  }

  constructor(
    src: string,
    altText: string,
    width?: "inherit" | number,
    height?: "inherit" | number,
    format?: ElementFormatType,
    key?: NodeKey
  ) {
    super(format, key);
    this.__src = src;
    this.__alt = altText;
    this.__width = width || "inherit";
    this.__height = height || "inherit";
  }

  exportJSON(): SerializedImageNode {
    return {
      altText: this.__alt,
      height: this.__height === "inherit" ? 0 : this.__height,
      src: this.__src,
      type: this.__type,
      version: 1,
      width: this.__width === "inherit" ? 0 : this.__width,
      format: this.__format,
    };
  }

  getTextContent(): string {
    return `![${this.__alt}](${this.__src})\n\n`;
  }

  createDOM(): HTMLElement {
    const el = document.createElement("div");
    // making it inline-block allows placing a cursor before or after
    // needed if image is only element in editor
    el.classList.add(css({display: "inline-block"}));
    el.classList.add(css({width: "100%"}));
    el.classList.add(css({textAlign: "center"}));
    return el;
  }

  updateDOM(): false {
    return false;
  }

  decorate(): JSX.Element {
    return <Image src={this.__src} alt={this.__alt} nodeKey={this.__key} />;
  }
}

const Image = ({alt, nodeKey, src}: {src: string; alt: string; nodeKey: string}) => {
  const {selectionClassName, editor} = useLexicalDecoratorNode(nodeKey);

  const className = css(
    {width: "auto", height: "auto", display: "inline-block"},
    selectionClassName
  );
  const styles = {maxWidth: 500, maxHeight: 182};

  const videoMime = getVideoMimeTypeFromUrl(src);
  if (videoMime)
    return (
      <video className={className} style={styles} controls>
        <source src={src} type={videoMime} />
      </video>
    );

  return (
    <RawMarkdownImg
      src={src}
      alt={alt}
      className={className}
      style={styles}
      onClick={(event: any) => {
        editor.dispatchCommand(CLICK_COMMAND, event);
      }}
    />
  );
};

export function $createImageNode({
  altText,
  height,
  src,
  width,
  format,
  key,
}: ImagePayload): ImageNode {
  return $applyNodeReplacement(new ImageNode(src, altText, width, height, format, key));
}

export function $isImageNode(node: LexicalNode | null | undefined): node is ImageNode {
  return node instanceof ImageNode;
}

const findAndTransformImage = (node: TextNode) => {
  const text = node.getTextContent();
  const imgRegex = /!(?:\[([^[]*)\])(?:\(([^(]+)\))/;
  const m = text.match(imgRegex);
  if (!m) return null;

  const selection = $getSelection();
  if ($isRangeSelection(selection) && !$getEditor()._updateTags.has("paste")) {
    if (selection.anchor.getNode() === node) {
      if (selection.anchor.offset < m.index! + m[0].length) return null;
    }
  }
  const [, altText, src] = m;
  const imgNode = $createImageNode({altText, src});
  if (m[0].length === text.trim().length) {
    // in the default case the image should make up the full text line
    const parent = node.getTopLevelElementOrThrow();
    const idx = node.getIndexWithinParent();
    const [leftTree, rightTree] = $splitNode(parent, idx);
    rightTree.getFirstChildOrThrow().remove();
    rightTree.insertBefore(imgNode);
    let nextChild = rightTree.getFirstChild();
    if ($isLineBreakNode(nextChild)) nextChild.remove();
    nextChild = rightTree.getFirstChild();
    if ($isLineBreakNode(nextChild)) nextChild.remove();
    if (leftTree) {
      let lastChild = leftTree.getLastChild();
      if ($isLineBreakNode(lastChild)) lastChild.remove();
      lastChild = leftTree.getLastChild();
      if ($isLineBreakNode(lastChild)) lastChild.remove();
    }
    return imgNode;
  } else {
    const i = m.index!;
    let targetNode;
    if (i === 0) {
      [targetNode] = node.splitText(i + m[0].length);
    } else {
      [, targetNode] = node.splitText(i, i + m[0].length);
    }
    const splitNode = targetNode.getParentOrThrow();
    const splitOffset = targetNode.getIndexWithinParent();
    const [, rightTree] = $splitNode(splitNode, splitOffset);
    targetNode.remove();

    rightTree.insertBefore(imgNode);

    let nextChild = rightTree.getFirstChild();
    if ($isLineBreakNode(nextChild)) nextChild.remove();
    nextChild = rightTree.getFirstChild();
    if ($isLineBreakNode(nextChild)) nextChild.remove();

    return imgNode;
  }
};

export const CdxDisplayMarkdownImagesPlugin = () => {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    return editor.registerNodeTransform(TextNode, (node) => {
      if (!node.isSimpleText()) {
        return;
      }
      findAndTransformImage(node);
    });
  }, [editor]);
  return null;
};
