// Had to fork it due to https://github.com/facebook/lexical/issues/4214

import type {
  BaseSelection,
  CommandPayloadType,
  ElementFormatType,
  LexicalEditor,
  TextFormatType,
} from "lexical";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {mergeRegister, objectKlassEquals} from "@lexical/utils";
import {DRAG_DROP_PASTE, eventFiles} from "@lexical/rich-text";
import {
  $getSelection,
  $isRangeSelection,
  $isTextNode,
  INDENT_CONTENT_COMMAND,
  OUTDENT_CONTENT_COMMAND,
  $isLineBreakNode,
  $createTextNode,
  COMMAND_PRIORITY_NORMAL,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  INSERT_PARAGRAPH_COMMAND,
  PASTE_COMMAND,
  isSelectionCapturedInDecoratorInput,
  COMMAND_PRIORITY_LOW,
} from "lexical";
import {useLayoutEffect} from "react";
import {
  getSelectionInfo,
  indentLine,
  outdentLine,
} from "./CdxSmartPlainTextCompletionPlugin/smartIndentHelpers";
import {parseLine} from "./CdxSmartPlainTextCompletionPlugin/parseMarkdownLineAst";
import {$isDecoratorBlockNode} from "@lexical/react/LexicalDecoratorBlockNode";

function isDOMNode(x: unknown): x is Node {
  return typeof x === "object" && x !== null && "nodeType" in x && typeof x.nodeType === "number";
}

function $insertDataTransferForPlainText(
  dataTransfer: DataTransfer,
  selection: BaseSelection
): void {
  let text = dataTransfer.getData("text/plain") || dataTransfer.getData("text/uri-list");

  if (text !== null) {
    text = text.replace(/\t/g, "  ");
    selection.insertRawText(text);
  }
}

function onPaste(event: CommandPayloadType<typeof PASTE_COMMAND>, editor: LexicalEditor): void {
  event.preventDefault();
  editor.update(
    () => {
      const selection = $getSelection();
      const clipboardData =
        objectKlassEquals(event, InputEvent) || objectKlassEquals(event, KeyboardEvent)
          ? null
          : (event as ClipboardEvent).clipboardData;
      if (clipboardData != null && selection !== null) {
        $insertDataTransferForPlainText(clipboardData, selection);
      }
    },
    {
      tag: "paste",
    }
  );
}

function registerOverwrites(editor: LexicalEditor): () => void {
  const removeListener = mergeRegister(
    editor.registerCommand(
      INDENT_CONTENT_COMMAND,
      () => {
        const selection = $getSelection();
        if (!$isRangeSelection(selection)) return false;
        // sanitizeSelection(selection);
        const selectionInfo = getSelectionInfo(selection);
        if (!selectionInfo) return false;
        for (const line of selectionInfo.lines) {
          if (line.length === 0) continue;
          const firstChild = line[0];
          if ($isLineBreakNode(firstChild)) continue;
          if ($isDecoratorBlockNode(firstChild)) continue;
          let diff = 0;
          if (!$isTextNode(firstChild)) {
            firstChild.insertBefore($createTextNode("  "));
            diff = 2;
          } else {
            const prevContent = firstChild.getTextContent();
            const nextContent = indentLine(parseLine(prevContent));
            diff = nextContent.length - prevContent.length;
            firstChild.setTextContent(nextContent);
          }
          if (firstChild.__key === selection.anchor.key) {
            selection.anchor.offset += diff;
          }
          if (firstChild.__key === selection.focus.key) {
            selection.focus.offset += diff;
          }
        }
        return true;
      },
      COMMAND_PRIORITY_NORMAL
    ),
    editor.registerCommand(
      OUTDENT_CONTENT_COMMAND,
      () => {
        const selection = $getSelection();
        if (!$isRangeSelection(selection)) return false;
        // sanitizeSelection(selection);
        const selectionInfo = getSelectionInfo(selection);
        if (!selectionInfo) return false;

        for (const line of selectionInfo.lines) {
          if (line.length === 0) continue;
          const firstChild = line[0];
          if ($isLineBreakNode(firstChild)) continue;
          if (!$isTextNode(firstChild)) continue;
          let diff = 0;

          const prevContent = firstChild.getTextContent();
          const nextContent = outdentLine(parseLine(prevContent));
          diff = nextContent.length - prevContent.length;
          firstChild.setTextContent(nextContent);

          if (firstChild.__key === selection.anchor.key) {
            selection.anchor.offset += diff;
          }
          if (firstChild.__key === selection.focus.key) {
            selection.focus.offset += diff;
          }
        }
        return true;
      },
      COMMAND_PRIORITY_NORMAL
    ),
    editor.registerCommand<ElementFormatType>(
      FORMAT_ELEMENT_COMMAND,
      (format) => {
        return true;
      },
      COMMAND_PRIORITY_NORMAL
    ),
    editor.registerCommand<TextFormatType>(
      FORMAT_TEXT_COMMAND,
      (format) => {
        return true;
      },
      COMMAND_PRIORITY_NORMAL
    ),
    editor.registerCommand(
      INSERT_PARAGRAPH_COMMAND,
      () => {
        const selection = $getSelection();
        if (!$isRangeSelection(selection)) {
          return false;
        }
        selection.insertLineBreak(false);
        return true;
      },
      COMMAND_PRIORITY_NORMAL
    ),
    editor.registerCommand(
      PASTE_COMMAND,
      (event) => {
        const [, files, hasTextContent] = eventFiles(event);
        if (files.length > 0 && !hasTextContent) {
          editor.dispatchCommand(DRAG_DROP_PASTE, files);
          return true;
        }

        // if inputs then paste within the input ignore creating a new node on paste event
        if (isDOMNode(event.target) && isSelectionCapturedInDecoratorInput(event.target)) {
          return false;
        }

        const selection = $getSelection();
        if (selection !== null) {
          onPaste(event, editor);
          return true;
        }

        return false;
      },
      COMMAND_PRIORITY_LOW
    )
  );
  return removeListener;
}

export function useSetup(editor: LexicalEditor): void {
  useLayoutEffect(() => {
    return registerOverwrites(editor);
    // We only do this for init
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);
}

export function CdxRichTextOverwritesPlugin() {
  const [editor] = useLexicalComposerContext();
  useSetup(editor);

  return null;
}
